import React from "react"
import { Link } from "gatsby"
import logo from '../../static/assets/edited-logo.png';

const Logo = (props) => (
  <div className="site-logo">
    <Link to="/">
      <img src={logo} width={120} height={80} alt="Limnosul" />
      {/*props.title*/}  
    </Link>
  </div>
)

export default Logo