import React from "react"
import {Link} from "gatsby"
import { RiInstagramLine } from "react-icons/ri";

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p>Visite nosso instagram para mais atualizações  <span className="icon -love">
          <RiInstagramLine/>
        </span>
        <Link to="https://www.instagram.com/limnosul/" target="_blank">
          Limnosul
        </Link>
      </p>
    </div>
  </footer>
)

export default Footer